export default {
	navPrev:"shift + 9",
	navNext:9,
	navUp:38,
	navDown:40,
	navLeft:37,
	navRight:39,
	scrollPageUp:33,
	scrollPageDown:34,
	scrollToStart:36,
	scrollToEnd:35,
};
